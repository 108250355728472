import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useLangFilter } from "../hooks"
import { DateIntl } from "../components/StyledText"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons"
import { Button } from "../components/Buttons"
import Img from "gatsby-image"
import { Link, FormattedMessage } from "gatsby-plugin-intl"

export default function BlogHighlight() {
  const [first, ...secondaries] = useHighlightBlogsByLang()

  return (
    <div className="xs:block md:grid grid-rows-1 grid-flow-col gap-4">
      <div className="row-span-3 col-span-1">
        <article className="p-4 bg-gray-100 h-full border hover:border-blue-400">
          <div className="flex flex-col">
            <div className="flex-1 bg-gray-200">
              <div className="block h-48 overflow-hidden">
                <Img
                  className="w-full"
                  fluid={first.node.image && first.node.image.fluid}
                  alt={first.node.title}
                />
              </div>
            </div>
            <div className="flex-1 pt-6 px-3">
              <h2 className="text-md text-gray-700">
                <FontAwesomeIcon icon={faCalendarAlt} className="mr-1 p2" />
                <DateIntl date={first.node.date} />
              </h2>
              <h4 className="text-2xl font-semibold">{first.node.title}</h4>
              <p className="mt-4 text-gray-700">
                {first.node.body.childMarkdownRemark.excerpt}
              </p>
              <p className="mt-4 text-gray-700">
                <Link to={`/${first.node.slug}`} className="link">
                  <Button primary>
                    <FormattedMessage id="viewMore" />
                  </Button>
                </Link>
              </p>
            </div>
          </div>
        </article>
      </div>
      {secondaries.map(({ node }, i) => (
        <div key={i} className={`row-span-${i + 1} col-span-1`}>
          <Link to={node.slug} className="link">
            <article className="p-4 bg-gray-100 border hover:border-blue-400">
              <div className="xs:block md:flex">
                <div className="xs:w-full md:w-1/3 bg-gray-200">
                  <div className="block overflow-hidden">
                    <Img
                      className="w-full h-64"
                      fluid={node.image && node.image.fluid}
                      alt={node.title}
                    />
                  </div>
                </div>
                <div className="xs:w-full md:w-2/3 p-6">
                  <h2 className="text-md text-gray-700">
                    <FontAwesomeIcon icon={faCalendarAlt} className="mr-1 p2" />
                    <DateIntl date={node.date} />
                  </h2>
                  <h4 className="text-xl font-semibold">{node.title}</h4>
                  <p className="mt-4 text-gray-700">{node.description}</p>
                </div>
              </div>
            </article>
          </Link>
        </div>
      ))}
    </div>
  )
}

export function useHighlightBlogsByLang() {
  const { allContentfulBlogPost } = useStaticQuery(
    graphql`
      query ListHBlogs {
        allContentfulBlogPost(
          sort: { fields: createdAt, order: DESC }
          limit: 6
        ) {
          edges {
            node {
              createdAt(fromNow: false)
              description
              labels
              node_locale
              slug
              title
              body {
                id
                body
                childMarkdownRemark {
                  excerpt(pruneLength: 300)
                }
              }
              id
              image {
                fluid(maxWidth: 786) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  )
  return useLangFilter(allContentfulBlogPost.edges)
}
