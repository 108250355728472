import React from "react"

export function CardContainer({ children, customClassName }) {
  return (
    <div
      className={
        customClassName ||
        "w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/3 mb-4 sm:p-0 md:p-2 lg:p-2"
      }
    >
      {children}
    </div>
  )
}
